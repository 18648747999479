@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;700;600&Catamaran:wght@600;700&display=swap');



/* Logo Text:   #65CD2B   (CMYK : 51,0,79,20)
Logo Background:    #03300A    (CMYK : 94,0,79,81)

If you want to use other shades of green:

#93E14F   (CMYK : 35,0,65,12)
#5BBB26    (CMYK : 58,0,83,14)

Font of Logo: 

Nourd Bold         Bolded

Header Font:

Catamaran Bold    Standard Format

Body Text Font:

Assistant Regular     Standard Format */

.App {
  background: linear-gradient(90deg, rgba(147,225,79,1) 0%, rgba(83,146,48,1) 0%, rgba(39,92,27,1) 0%, rgba(24,74,20,1) 0%, rgba(15,62,16,1) 9%, rgba(3,48,10,1) 27%, rgba(3,48,10,1) 73%, rgba(15,62,16,1) 91%, rgba(24,74,20,1) 100%, rgba(39,92,27,1) 100%, rgba(83,146,48,1) 100%, rgba(147,225,79,1) 100%);
}

.App-header {
  min-height: 5vmin;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.App-link {
  color: #61dafb;
}

.App-header {
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  background: linear-gradient(90deg, rgba(147,225,79,1) 0%, rgba(83,146,48,1) 0%, rgba(39,92,27,1) 0%, rgba(24,74,20,1) 0%, rgba(15,62,16,1) 9%, rgba(3,48,10,1) 27%, rgba(3,48,10,1) 73%, rgba(15,62,16,1) 91%, rgba(24,74,20,1) 100%, rgba(39,92,27,1) 100%, rgba(83,146,48,1) 100%, rgba(147,225,79,1) 100%);
}

.App-header a {
  color: lightgray;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 16px; 
  line-height: 25px;
  border-radius: 4px;
}

.App-header {
  height: 100px;
}

.App-logo {
  width: 175px; 
  height: 175px; 
  object-fit: cover;
  object-position: 25% 25%; 
}

.App-header a:focus {
  color: #65CD2B;
}

.App-header a:hover {
  background-color: #93E14F;
  color: black;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(5vmin + 80px);
  position: relative;
  overflow: hidden;
  min-height: calc(100vh);
}

.preview-img {
  height: 40vh;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  transform: rotate3d(1, 1, 1, 332deg);
}

.preview-img:first-child {
  bottom: -40px;
  left: 40px;
}

.preview-wrapper {
  display: flex;
}

.two-col {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.two-col * {
  margin-bottom: 20px;
}

.main-header {
  display:flex;
  justify-content:space-around;
  align-items:center;
}

.icon {
  display: none;
}

.title,
.subtitle {
  font-family: 'Catamaran', sans-serif;
  font-weight: 700;
  font-size: calc(20px + 2vmin);
  color: #65CD2B;
  margin: 0 0 20px 0;
}

.title {
  max-width: 90vw;
}

.title > span {
  color: #93E14F;
}

.subtitle {
  color: #93E14F;
  font-size: calc(20px + 2vmin);
}

.hashtag {
  color: #93E14F;
  font-size: calc(20px + 2vmin);
  max-width: 700px;
  margin: 0 0 20px 0;
}

.text {
  font-family: 'Assistant', sans-serif;
  font-size: 20px;
  color: #65CD2B;
  font-weight: 400;
  margin: 5px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 750px) and (min-width: 501px) {
  .App-logo {
    width: calc(120px - 2vmin);; 
    height: calc(120px - 2vmin);; 
    object-fit: cover;
    object-position: 25% 25%; 
  }
  .two-col {
    flex-direction: column;
  }  
}

@media screen and (max-width: 500px) {
  .App-header {
    flex-direction: column;
    align-items: center;
  }

  .two-col {
    flex-direction: column;
  }
  
  .App-header a {
    display: block;
    cursor: pointer;
  }
  
  .header-right {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .preview-img {
    height: 33vh;
  }

  .icon {
    display: block;
  }

  .icon:hover + .header-right {
    display: flex;
  }

  .main-header {
    width:100vw;
  }
}
